import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { NotificationMessageComponent } from '@scriptac/common/shared/components/notification-message/notification-message.component';

import { NotificationType, NotificationOptions } from '../models/notification-options';

const PANEL_CLASSES: Record<NotificationType, string> = {
	primary: 'primary-message',
	success: 'success-message',
	warn: 'warn-message',
};

const DEFAULT_DURATION = 5 * 1000;

/**
 * Service to show notification.
 */
@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	private readonly snackBar = inject(MatSnackBar);

	/** Close notification. */
	public close(): void {
		this.snackBar.dismiss();
	}

	/**
	 * Show notification message.
	 * @param options Options to show the notification.
	 */
	public showMessage(options: NotificationOptions): void {
		this.snackBar.openFromComponent(NotificationMessageComponent, {
			data: options,
			duration: options.duration ?? undefined,
			horizontalPosition: 'center',
			panelClass: ['custom-notification', PANEL_CLASSES[options.type ?? 'primary']],
		});
	}

	/**
	 * Show primary notification.
	 * @param message Notification text.
	 * @param duration Time in milliseconds before auto-close (by default 5 seconds).
	 */
	public showPrimary(message: string, duration: number | null = DEFAULT_DURATION): void {
		this.showMessage({
			message,
			duration,
			type: 'primary',
		});
	}

	/**
	 * Show secondary notification.
	 * @param message Notification text.
	 * @param duration Time in milliseconds before auto-close (by default 5 seconds).
	 */
	public showSuccess(message: string, duration: number | null = DEFAULT_DURATION): void {
		this.showMessage({
			message,
			duration,
			type: 'success',
		});
	}

	/**
	 * Show warning notification.
	 * @param message Notification text.
	 * @param duration Time in milliseconds before auto-close.
	 */
	public showWarn(message: string, duration: number | null = DEFAULT_DURATION): void {
		this.showMessage({
			message,
			duration,
			type: 'warn',
		});
	}
}
