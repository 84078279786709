import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

import { NotificationOptions } from '@scriptac/common/core/models/notification-options';

/** Custom notification component. */
@Component({
	selector: 'scriptac-notification-message',
	templateUrl: './notification-message.component.html',
	styleUrls: ['./notification-message.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationMessageComponent {

	private readonly matSnackBarService = inject(MatSnackBar);

	/** Options. */
	protected readonly options = inject<NotificationOptions>(MAT_SNACK_BAR_DATA);

	/** Is warning message. */
	public get isWarn(): boolean {
		return this.options.type === 'warn';
	}

	/** Close the snackbar. */
	public closeSnackbar(): void {
		this.matSnackBarService.dismiss();
	}
}
